<template>
  <div class="d-flex flex-column align-center">
    <v-btn
      color="primary"
      class="white--text pr-8 pl-8 btn-margin"
      :to="{ name: 'InformacionRestaurante' }"
      >CREAR NUEVO RESTAURANTE</v-btn
    >
    <v-row justify="center" class="mt-12">
      <v-col col="12" sm="12" md="6" lg="8">
        <img
          width="100%"
          :src="require('@/assets/crear-menu.png')"
          alt="icon edit"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {};
</script>
<template>
  <v-container>
    <div class="container-global">
      <v-skeleton-loader
        v-show="showSkeInfo"
        class="mx-auto"
        max-width="100%"
        max-height="500px"
        type="table-heading, actions, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
      ></v-skeleton-loader>
      <template v-if="listRestaurantes.length > 0 && !showSkeInfo">
        <div class="d-flex align-center justify-space-between mb-12">
          <h2 class="mb-0">Mis restaurantes</h2>
          <template v-if="membresiaUsuario">
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                class="white--text pr-8 pl-8 btn-margin"
                :to="{ name: 'InformacionRestaurante' }"
                v-if="
                  listRestaurantes.length <
                  membresiaUsuario.cantidadRestaurantes
                "
                >CREAR NUEVO RESTAURANTE</v-btn
              >
            </div>
          </template>
          <template v-else>
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                class="white--text pr-8 pl-8 btn-margin"
                :to="{ name: 'InformacionRestaurante' }"
                v-if="
                  listRestaurantes.length == 0
                "
                >CREAR NUEVO RESTAURANTE</v-btn
              >
            </div>
          </template>
        </div>
      </template>
      <ul
        class="list-restaurantes"
        v-if="listRestaurantes.length > 0 && !showSkeInfo"
      >
        <li v-for="(item, index) in listRestaurantes" :key="index">
          <CardRestaurante :item="item"></CardRestaurante>
        </li>
      </ul>
      <EmpezarRestaurante
        v-if="listRestaurantes.length == 0 && !showSkeInfo"
      ></EmpezarRestaurante>
    </div>
  </v-container>
</template>
<script>
import EmpezarRestaurante from "@/components/restaurante/EmpezarRestaurante";
import CardRestaurante from "@/components/restaurante/CardRestaurante";
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("moduloRestaurantes", ["listRestaurantes"]),
    ...mapState("adminSuscripciones", ["membresiaUsuario"]),
  },
  components: {
    CardRestaurante,
    EmpezarRestaurante,
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["obtieneRestaurantes"]),
    async getRestaurantes() {
      const result = await this.obtieneRestaurantes();
      if (result.res) {
        this.showSkeInfo = false;
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
    },
  },
  created() {
    this.getRestaurantes();
  },
  data() {
    return {
      showSkeInfo: true,
    };
  },
};
</script>
<style lang="scss">
.list-restaurantes {
  list-style: none;
  padding: 0 !important;
  margin: 0;
  border-top: 1px solid #000000;
  li {
    padding: 12px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .card-restaurante {
    display: flex;
    align-items: center;
    .container-info {
      flex-basis: 60%;
      display: flex;
      align-items: center;
      .icon-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        flex-shrink: 0;
        margin-right: 35px;
        &.empty {
          background-color: #c4c4c4;
        }
        @media (max-width: 1024px) {
          height: 60px;
          width: 60px;
          margin-right: 0;
          margin-bottom: 12px;
        }
      }
      .title-info {
        margin-bottom: 0;
        max-width: 60%;
      }
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 100%;
        margin-bottom: 16px;
      }
    }
    .container-actions {
      flex-basis: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-margin {
        margin-right: 25px;
        //movil
        @media (max-width: 480px) {
          margin-bottom: 16px;
          margin-right: 0;
        }
      }
      @media (max-width: 1024px) {
        flex-basis: 100%;
        padding-left: 24px;
      }
      //movil
      @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>